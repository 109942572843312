import styled from "styled-components";

export const ContainerGeral = styled.div`
    flex: 1;
    width: 100%;
    /* height: 100vh; */
    background-color: blue;
    display: flex;
    flex-direction: column;
    background-color: #f3f4f6;
    padding: 25px;
    overflow: auto;
    
    @media only screen and (max-width: 600px) {
        padding: 4px;
    }
`;
export const SubContainerGeral = styled.div`
    flex: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

`;
export const ContaineRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
 
    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:14px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 750px){
        border-bottom: none;

        .container__addIcon  {

            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;
export const ContaineRow2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   

    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:14px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 750px){
        flex-direction: column;
        height: auto;
        border-bottom: none;
        .container__addIcon  {

            min-width: 100%;
            height: 30px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;
            padding: 0px 15px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;

export const BotaoNovo = styled.div`
    background-color: ${c => c.cor};
`;
export const BotaoProdutos = styled.div`
    background-color: ${c => c.cor};
`;
export const ContainerBotao = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 15px;
    gap:10px;
    position: relative;

    .div_options {
        z-index: 1000;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap:10px;

    }
    .div_subptions {
        z-index: 2001;
        position: absolute;
        width: 300px;
        min-height: 50px;
        z-index: 2000;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:5px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        right: -300px;
    }
    
    cursor: pointer;
    
    p {
        font-weight: 500;
        color: #444746;
    }
    
    svg {
        width: 20px;
        height: 20px;
        color: #444746;
    }
    
`;
export const ContainerBotao2 = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 15px;
    gap:10px;
    position: relative;
    border-top: 1px solid #A5AAAD;

    .div_options {
        z-index: 1000;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap:10px;

    }
    .div_subptions {
        z-index: 2001;
        position: absolute;
        width: 300px;
        min-height: 50px;
        z-index: 2000;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:5px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        right: -300px;
    }
    
    cursor: pointer;
    
    p {
        color: #444746;
        font-size: 12px;
    }
    
    svg {
        width: 20px;
        height: 20px;
        color: #444746;
    }
    
`;

export const ContainerRowGrupo = styled.div`
   
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px;
    gap: 10px;
    padding-bottom: 1000px;
    /* border: 1px solid grey; */
    background-color: #fff;
    
    .container__addIcon  {
        width: 33px;
        height: 33px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #469CAC;
        cursor: pointer;
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }
    
    @media only screen and (max-width: 600px){
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
   
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  gap: 15px;
  max-height: 80vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
export const ContainerScroll = styled.div`

    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 25px;
    gap: 5px;
    padding-bottom: 100px;
    /* background-color: #fff; */
    max-height: 800px;
    margin-bottom: 10px;
    overflow: auto;
    @media only screen and (max-width: 600px){
        padding: 10px;
    }
`;
export const ContainerScroll3 = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 88vh;
    gap: 5px;
    padding-bottom: 100px;

    @media only screen and (max-width: 600px){
        height: 55vh;
    }
`;

export const ContainerRowGrupo2 = styled.div`
   
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 0px;
    gap: 10px;
    padding-bottom: 1000px;
    /* border: 1px solid grey; */
    /* background-color: #fff; */
    
    .container__addIcon  {
        width: 33px;
        height: 33px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #469CAC;
        cursor: pointer;
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 600px){
        flex-direction: column;
        align-items: center;
    }
   
`;
export const ContainerScroll2 = styled.div`
    overflow-y: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 70vh;
    gap: 30px;
    padding-bottom: 100px;


    @media only screen and (max-width: 600px){
        height: 55vh;
    }
`;

export const TituloGrupo = styled.h1`
    /* background-color: #fff; */
    font-size: 16px;
    margin: 25px 25px 0px 25px;
    color: #143429;

    @media only screen and (max-width: 600px){
       
    }
`;
export const ContainerCard = styled.tr`
  margin-top: 10px;
   padding: 20px 35px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  border-left: solid 5px ${(c) => c.cor};

`;
export const GreetingHeader = styled.div`


`;
export const Greeting = styled.div`
   display: flex;
    gap: 10px;
`;


export const ContainerEstoqueBaixo = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
        width: 20px;
        height: 20px;
        color: #E91E63;
    }
    h6 {
        font-size: 13px;
        font-weight: 700;
        color: #143429;
    }
    
    
`;
export const ContainerInputPesq = styled.div`
    width: 100%;
    text-align: center;
    font-family: Arial !important;
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    padding: 35px 25px;
    border-radius: 5px;
`;
export const ContainerInputPesq2 = styled.div`
    width: 100%;
    text-align: center;
    font-family: Arial !important;
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    padding: 0px 25px 35px 25px;
    border-radius: 5px;
`;
export const ContainerFildes = styled.div`
    width: 100%;
    text-align: center;
    font-family: Arial !important;
    display: inline-flex;
    flex-wrap: wrap; 
    background-color: #fff;
    border-radius: 5px;
    `;
export const ContainerFildes2 = styled.div`
    width: 100%;
    height: auto;
    font-family: Arial !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    gap: 10px;

    input {
        width: 100%;
        flex: 1;
    }

`;
export const ContainerInputFi = styled.div`
    min-width: 250px;
    flex: 1;
    margin: 4px;
`;
export const ContainerInputFi2 = styled.div`
    min-width: 200px;
    margin: 4px;
`;


export const BotaoInsertImg = styled.div`
    width: 85px;
    height: 85px;
    border-radius: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #469CAC;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff ;
    margin-top: 15px;
    img {
        width: 84px;
        height: 84px;
        border-radius: 84px;
        object-fit: cover;
    }

`;
export const DivVaziaImg = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 90%;
        height: 90%;
        object-fit: contain;
    }
    h1 {
        font-size: 18px;
    }

`;

export const Tabela = styled.table`
    width: 100%;
    height: auto;

`;
export const LinhasTR = styled.tr`
    border-bottom: 1px solid #F0F0F0 ;
    padding: 10px;
    margin-bottom: 10px;
`;
export const LinhasTRBody = styled.tr`
   border-left: 3px solid ${c => c.cor};

   &:nth-child(even) {
        background-color: #F0F0F0;
        /* background-color: #d2f9f9; */
        color: #fff;
        
    }

`;
export const ColunasTH = styled.th`
    width: 25px;
    height: 25px;
    display: ${props => props.ocultar};
    padding: 10px;
    & div {
        font: sans-serif ;
        font-size: 12px;
        color: #fff;
        padding: 2px;
        font-weight: bold;
        
        
    }
    
    .check {
        width: 15px;
    }
    
    & input {
        font-size: 8px;
        padding: 3px;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    
    @media only screen and (max-width: 500px) {
        width: 16px;
        padding: 6px;
    }

    `;
export const ColunasTD = styled.td`
   width: 25px;
   height: auto;
   font-size: 8px;
   text-align: center;
   display: ${props => props.ocultar};
   /* background-color: red; */
   padding: 5px;
   
   text-align: center;
   div {
       width: 100%;
       height: auto;
       display: flex;
       justify-content: center;
       align-items: center;
       /* background-color: red; */
    }
    img {
        height: 30px;
        width: 30px;
        border-radius: 3px;
        object-fit: contain;
        background-color: #469CAC;
    }
p {
    width: 100%;
    
}
.check {
    width: 15px;
}

& input {
    font-size: 8px;
    padding: 3px;
    width: 100%;
    height: 100%;
    text-align: center;
}

@media only screen and (max-width: 500px) {
    width: 16px;
}
`;



export const ContainerModalOpcoes = styled.div`
    transition: all ease-in-out .2s;

    width:100%;
    height: 100% ;
    position: fixed;
    top: 0px;
    right: 0px;
    /* background-color: #0000009e; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: none;

    @media print{
        display: block;
        height: auto;
        margin: 0px;
        padding: 0px;
        .ocultar{
            display: none;
        }
    }

`;

export const ModalOpcoesBotoesDinamico = styled.div`
    min-width: 230px;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 2000;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding:  10px 0px;
    position: absolute;
    top: ${p => (p.pos.y + 10)}px;
    left: ${p => p.pos.x - 230}px;
`;
export const ContainerScrollCategorias = styled.div`
   width: 100%;
    overflow-y: auto;
    
    
   
`;
export const ContainerFechar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    /* background-color: red; */
    gap: 15px;
    padding: 5px 15px 0px 15px;

    svg {
        width: 25px;
        height: 25px;
        color: #143429;
    }
    p {
        color: #143429;
    }

    
   
`;


// card 

export const Cardproduto = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
`;
export const ContprodutosD = styled.div`
display: flex;
gap: 10px;
`;
export const ContImgproduto = styled.div`
display: flex;
align-items: center;
img {
    height: 80px;
    width: 80px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
  }

`;
export const TituloVeic = styled.div`
font-size: 14pt;
font-weight: bold;
`;
export const ContDadosproduto = styled.div`
display: flex;
gap: 5px;
flex-direction: column;
h1 {
    font-size: 12px;
    color: #2e4a66;
    margin-bottom: 5px;
    text-align: left;
  }
  p {
    font-size: 10px;
    font-weight: 700;
    color: #a5aaad;
  }
`;



export const ContainerDados = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;

  img {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
  }
`;
export const ContainerBotoes = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  gap: 7px;
`;

export const BotoesCard = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(p) => p.cor};
  padding: 5px 0px;
  gap: 5px;


  svg {
    color: ${(p) => p.cor};
    width: 20px;
    height: 20px;
  }
`;

export const ContainerPesquisaSel = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  border-radius: 4px;
  flex-direction: column;
  padding-right: 10px;
`;
export const TextSelect = styled.div`


display: flex;
align-items: center;
font-size: 13px;
justify-content: center;
`;
export const ContentSelect = styled.div`

display: flex;
align-items: center;
padding: 2px;
select {
text-align: center;
  width: 100%;
  border: none;
font-weight: 100;
color: #959595;
}
`;