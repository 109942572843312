import styled from 'styled-components';

// Estilos com Styled Components
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: 100vh;
  background-color: #f5f5f5;
`;
// Header com navegação
export const Header = styled.header`
  width: 100%;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;
export const ContaineRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:14px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
 

    @media only screen and (max-width: 768px){
        border-bottom: none;

        .container__addIcon  {

            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;
export const BotaoProdutos = styled.div`
    background-color: ${c => c.cor};
`;
export const Logo = styled.h1`
  font-size: 1.5rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
export const Nav = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
`;
export const NavLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Resto da tela de financeiro
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  gap: 15px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 0.9rem;
  }
`;

export const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
`;

export const ListItem = styled.li`
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ListItemText = styled.span`
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const ListItemAmount = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: red;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

//Card Cliente
export const ContainerCard = styled.div`
  padding: 20px 35px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  border-left: solid 5px ${(c) => c.cor};

  img {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
    background-color: #469cac;
  }
`;
export const Greeting = styled.div`
  h1 {
    font-size: 18px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }
`;
////////////////////////////////////////////