import styled from 'styled-components';
import { destaque2 } from '../../styles/coresPadroes';

export const ContainerGeral = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    background-color: #f4f8ff;
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px 20px ;
`;

export const ContainerTitulo = styled.div`
    width: 100%;
    height: auto;
    background-color: ${destaque2};
    text-align: center;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    z-index: 1000000000;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        h1{
            font-size: 16px;
        }
    }
`

//div filho
export const ContainerChildren = styled.div`
    flex: 1;
    overflow: auto;
`

//fechar tela
export const BtnFechar = styled.div`
    position: absolute;
    top: 5px;
    right: 10px;
    color: #ee1727;
    cursor: pointer;
    font-family: Arial;
    font-size: 18px;
    font-weight: 900;
    padding: 1px;
    text-shadow: 1px 1px #ee1727;
`


