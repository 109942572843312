import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Home from "../pages/Home/home";
import Clientes from "../pages/clientes/clientes";
import CadVeiculos from "../pages/veiculos/cadveiculo/veiculos";
import Consultas from "../pages/consultas/consultas";
import Produtos from "../pages/produtos/produtos";
import Usuarios from "../pages/usuarios/usuarios";

import Vendas from "../pages/vendas/vendas";
import Contratos from "../pages/alugueis/contratos";
import FinanceiroScreen from "../pages/financeiro/financeiro";

//LISTAS
import ListaVeiculos from "../pages/veiculos/lista/listaVeiculos";


export default function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={'/home'} />} />
            <Route path='/home' element={<Home />} />
            <Route path='/vendas' element={<Vendas />} />
            <Route path='/contratos' element={<Contratos />} />
            <Route path='/financeiro' element={<FinanceiroScreen />} />
            <Route path='/clientes' element={<Clientes />} />
            <Route path='/cadveiculos/:objeto' element={<CadVeiculos />} />
            <Route path='/listaVeiculos/:id_cliente' element={<ListaVeiculos />} />
            <Route path='/produtos' element={<Produtos />} />
            <Route path='/consultas' element={<Consultas />} />
            <Route path='/usuarios' element={<Usuarios />} />

            {/* =========================================================================== */}
            {/*CASO VENHA DO LOGIN, ENVIA PARA A HOME, CASO NÃO ENVIA PARA A PAGINA NÃO ENCONTRADA  */}
            <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
    );
}