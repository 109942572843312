import styled from "styled-components";

export const ContainerGeral = styled.div`
    flex: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f3f4f6;
    padding: 25px;
    overflow: auto;
    
    @media only screen and (max-width: 600px) {
        padding: 4px;
    }
`;
export const SubContainerGeral = styled.div`
    flex: 1;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
`;
export const ContaineRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid ${c => c.cor};
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .container__addIcon  {

        min-width: 60px;
        height: 50px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 10px;
        padding: 0px 15px;

        p {
            font-size:12px ;
            font-weight: bold;
            color: #fff;
        }
    }
    .Icon  {
        color: #fff;
        width: 20px;
        height: 20px;
    }
    .voltar {
        color: #A5AAAD;
    }

    @media only screen and (max-width: 750px){
        border-bottom: none;

        .container__addIcon  {

            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 10px;

            p {
                font-size:11px ;
                font-weight: bold;
                color: #fff;
            }
        }
        .Icon  {
            color: #fff;
            width: 14px;
            height: 14px;
        }
        
    }
   
`;

export const BotaoProdutos = styled.div`
    background-color: ${c => c.cor};
`;

export const ContainerScroll = styled.div`
    overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  gap: 20px;
  padding-bottom: 100px;

  @media only screen and (max-width: 600px) {
    height: 55vh;
  }
`;

export const ContainerCard = styled.tr`
margin-top: 10px;
   padding: 20px 35px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  border-left: solid 5px ${(c) => c.cor};

`;
export const ContainerDados = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;

  img {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
  }
`;
export const ContainerBotoes = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  gap: 7px;
`;
export const Greeting = styled.div`
   h1 {
    font-size: 18px;
    color: #2e4a66;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
  }

`;
export const BotoesCard = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(p) => p.cor};
  padding: 5px 0px;
  gap: 5px;


  svg {
    color: ${(p) => p.cor};
    width: 20px;
    height: 20px;
  }
`;

export const DivVaziaImg = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 90%;
        height: 90%;
        object-fit: contain;
    }
    h1 {
        font-size: 18px;
    }

`;

// modal Detalhes
export const ContainerModDin = styled.div`
  display: flex;
  width: 100%;
    font-weight: bold;
  flex-direction: column;
    align-items: center;
  h1 {
    font-size: 17px; 
    text-align: center;
    margin: 10px 0px;
    font-weight: bold;
  }
  p {
    text-align: left;
    width: 70%;
    padding: 2px 10px;
  }
`;
// modal Detalhes
export const hdModal = styled.div`
position: fixed;
width: 100%;
z-index: 99999999999;
background-color: red;
height: 100vh;
width: 100vw;
top: 0px;
left: 0px;
`;
export const ContainerModDin2 = styled.div`
  display: flex;
  width: 100%;
    font-weight: bold;
  flex-direction: column;
    align-items: center;
  h1 {
    font-size: 17px; 
    text-align: center;
    margin: 10px 0px;
    font-weight: bold;
  }
  padding: 10px;
gap: 5px;
`;

export const Card = styled.div`
  background-color: ${(props) => (props.status === 'pago' ? '#e0ffe0' : props.status == 'em dia' ? '#fff' : '#ffe0e0')}; // Verde se pago, vermelho se não pago
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTitle = styled.h3`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

export const CardContent = styled.p`
  font-size: 1em;
  margin: 5px 0;
`;



export const CardPay = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
`;

export const Contleft = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;

export const ContMid = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;

  p {
    margin: 3px 0;
    font-size: 16px;
    line-height: 1.4;
  }
`;

export const ContRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BallGreen = styled.div`
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: #28a745;
  box-shadow: 0 0 10px rgba(40, 167, 69, 0.5);
`;

export const BallOrange = styled.div`
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: #ffc107;
  box-shadow: 0 0 10px rgba(255, 193, 7, 0.5);
`;




export const Cardveiculo = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
`;
export const ContVeiculosD = styled.div`
display: flex;
gap: 10px;
`;
export const ContImgVeiculo = styled.div`
display: flex;
align-items: center;
img {
    height: 80px;
    width: 80px;
    border-radius: 70px;
    object-fit: cover;
    margin-right: 20px;
  }

`;
export const TituloVeic = styled.div`
font-size: 14pt;
font-weight: bold;
`;
export const ContDadosVeiculo = styled.div`
display: flex;
gap: 5px;
flex-direction: column;
h1 {
    font-size: 12px;
    color: #2e4a66;
    margin-bottom: 5px;
    text-align: left;
  }
  p {
    font-size: 10px;
    font-weight: 700;
    color: #a5aaad;
  }
`;
