import React, { useState } from "react";
import * as s from "./styles";
import ReactModal from 'react-modal'; //https://reactcommunity.org/react-modal/

/**Modal personalizado */
export default function Modal({ children, exibir, titulo = null, setStateExibir, styleContainerGeral = null, ...restProsp }) {

    //Fechar modal
    function fechar(valor) {
        setStateExibir(valor);
    }

    return (
        <ReactModal
            isOpen={exibir}
            appElement={document.getElementById('root')}
        >
            <s.ContainerGeral style={styleContainerGeral} >
                <s.BtnFechar onClick={() => { fechar(false) }}>X</s.BtnFechar>
                {
                    titulo &&
                    <s.ContainerTitulo>
                        <h1>{titulo}</h1>
                    </s.ContainerTitulo>
                }
                <s.ContainerChildren {...restProsp} >
                    {children}
                </s.ContainerChildren>
            </s.ContainerGeral>
        </ReactModal>
    )
}