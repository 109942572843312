import React, { useContext, useEffect, useState } from 'react';
import * as s from './estiloFinanceiro'
import * as Fa from "react-icons/fa";
import { vermelho2 } from '../../styles/coresPadroes';
import api from './../../services/apiAxios';
import { FaUserGroup } from "react-icons/fa6";
import { AuthContext } from '../../contexts/authContext';

// Componente principal
const FinanceiroScreen = () => {
  const {Spinner} = useContext(AuthContext)
  const [inadimplencias, setInadimplencias] = useState([])
  const [tipo, setTipo] = useState(1)
  const cor = [vermelho2, '#17A398', '#963484', '#064789']


  useEffect(() => {
    buscarInadiplencias()
    
  }, [tipo])

  async function buscarInadiplencias() {
    Spinner(true)

    try {
      const res = await api.get(`pagamentos/pendentes`)
      setInadimplencias(res.data.resultado)
      Spinner(false)

    } catch (error) {
      Spinner(false)
      console.log('error :>> ', error);
    }
  }

  function functionCor(index) {
    const cor = ["#2e4a66", "#a98921", "#469cac", "#3b9668"];
    if (index % 4 == 0) {
      return cor[0];
    } else if (index % 4 == 1) {
      return cor[1];
    } else if (index % 4 == 2) {
      return cor[2];
    } else if (index % 4 == 3) {
      return cor[3];
    }
  }


  return (
    <s.Container>

      <s.Header>

        <s.ContaineRow cor={cor[tipo - 1]}>

          <s.BotaoProdutos
            cor={tipo == 1 ? cor[tipo - 1] : '#A5ABBA'}
            className='container__addIcon'
            onClick={() => { setTipo(1) }}>
            <FaUserGroup
              className='fa fa-times Icon'
              id='Icon'
              aria-hidden='true'
            />
            <p>Clientes Inadimplentes</p>
          </s.BotaoProdutos>

          <s.BotaoProdutos
            cor={tipo == 2 ? cor[tipo - 1] : '#A5ABBA'}
            className='container__addIcon'
            onClick={() => { setTipo(2) }}>

            <Fa.FaMoneyCheck
              className='fa fa-times Icon'
              id='Icon'
              aria-hidden='true'
            />

            <p>Boletos a vencer</p>
          </s.BotaoProdutos>

        </s.ContaineRow>

      </s.Header>

      {
        tipo == 1 &&
        <s.Content>
            {
              inadimplencias.map((inadimplente, index) => (

                <s.ContainerCard key={index}
                  cor={functionCor(index)}
                  onClick={() => { }}
                >
                  <img
                    src={inadimplente.foto_cliente != null ? inadimplente.foto_cliente : noimage}
                    alt="foto-do-cliente"
                  />

                  <s.Greeting>
                    <h1>
                      {inadimplente.nome} {inadimplente.sobrenome}
                    </h1>
                    <p>Código do cliente: {inadimplente.id_cliente}</p>
                    <p>Qtd. Pg. pendentes: {inadimplente.total_pagamentos_pendentes}</p>
                    <p>Total: R$ {inadimplente.total_valor_pendente}</p>
                  </s.Greeting>

                </s.ContainerCard>

              ))
            }
        </s.Content>
      }

      {
        tipo == 2 &&
        <s.Content>
          Lista de boletos a vencer
        </s.Content>
      }

    </s.Container>
  );
};

export default FinanceiroScreen;
